import React, {useEffect} from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import SEO from "/src/components/seo"
import { Tab, Tabs, Nav } from "react-bootstrap"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
  } from "@material-ui/core"
import "./Services.scss"
import "./artificial-intelligence-consulting.scss"
import ServiceTemplate from "../../templates/services"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRightLong, faAngleDown, faAngleRight, faAngleLeft, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons"

const bannerContent = {
    title: "Artificial Intelligence Consulting",
    content: "At Neurond, we specialize in delivering tailored artificial intelligence consulting services that empower organizations to utilize AI’s full potential. Whether you’re exploring AI for the first time or scaling existing initiatives, our experts guide you from strategy to execution, ensuring measurable results.",
    backgroundImage: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/ai-consulting-intro.png`,
    backgroundImageSmall: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/ai-consulting-intro-img.png`,
    ctaText: "Get a Free AI Consultation"
}

const clientLogos = [
    `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/logo-plenti.png`,
    `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/logo-fishwell.png`,
    `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/logo-conexus.png`,
    `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/logo-incloud.png`,
    `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/logo-orient.png`,
    `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/logo-bravo.png`,
]

const ourServices = [
    {
        title: "AI Strategy Consulting",
        content: "Craft a future-proof roadmap aligned with your business goals.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-1.svg`
    },
    {
        title: "AI Technologies Consulting",
        content: "Navigate the rapidly evolving AI ecosystem with confidence.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-2.svg`
    },
    {
        title: "Data Strategy and Preparation",
        content: "Discover AI’s potential with clean, actionable data.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-3.svg`
    },
    {
        title: "Performance Monitoring and Optimization",
        content: "Maximize the value of your AI investments.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-4.svg`
    },
    {
        title: "Ethical AI and Compliance Consulting",
        content: "Build trust and mitigate risks.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-5.svg`
    },
    {
        title: "Change Management and Adoption",
        content: "Drive organizational buy-in and seamless transitions.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-6.svg`
    }
]

const ourProcess = [
    {
        title: "Discover and Define",
        content: "Understanding your unique needs",
        contentHighLight: ["Goal Alignment:", "Data Audit:", "ROI-Driven Use Cases:"],
        contentDetail: ["Identify your challenges, opportunities, and AI-ready workflows.", "Assess your data infrastructure, quality, and readiness for AI integration.", "Prioritize high-impact projects with clear business value."],
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-process-1.svg`,
        iconHover: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-process-1-hover.svg`
    },
    {
        title: "Design and Strategize",
        content: "Building your AI roadmap",
        contentHighLight: ["Tailored AI Strategy:", "Ethical Frameworks:", "Stakeholder Buy-In:"],
        contentDetail: ["Craft a roadmap aligned with your budget, timeline, and goals.", "Ensure compliance, fairness, and transparency in every solution.", "Present actionable plans to secure executive and team alignment."],
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-process-2.svg`,
        iconHover: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-process-2-hover.svg`
    },
    {
        title: "Build and Test",
        content: "Agile development, real-world validation",
        contentHighLight: ["Prototype Development:", "Performance Testing:", "User Feedback Loops:"],
        contentDetail: ["Rapidly build and refine AI models using your data.", "Validate accuracy, scalability, and integration readiness.", "Involve your team early to ensure usability and adoption."],
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-process-3.svg`,
        iconHover: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-process-3-hover.svg`
    },
    {
        title: "Deploy and Scale",
        content: "Launching solutions that grow with you",
        contentHighLight: ["Seamless Integration:", "Change Management:", "Scalability Planning:"],
        contentDetail: ["Embed AI tools into your workflows with minimal disruption.", "Train teams and optimize processes for smooth transitions.", "Architect systems to adapt to future needs and data growth."],
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-process-4.svg`,
        iconHover: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-process-4-hover.svg`
    },
    {
        title: "Optimize and Sustain",
        content: "Continuous improvement, lasting success",
        contentHighLight: ["Performance Monitoring:", "Iterative Refinement:", "Ongoing Support:"],
        contentDetail: ["Track KPIs like cost savings, efficiency gains, and ROI.", "Update models with new data and evolving business needs.", "Stay ahead with maintenance, upgrades, and strategy reviews."],
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-process-5.svg`,
        iconHover: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-process-5-hover.svg`
    },
]

const ourExperts = [
    {
        title: "Customized Solutions for Your Business",
        content: "Generic AI tools rarely address unique challenges. Consulting experts can analyze your data, workflows, and objectives to design bespoke strategies.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-7.svg`
    },
    {
        title: "Accelerated Time-to-Value",
        content: "Developing AI solutions in-house requires significant time and resources. Consultants can identify high-impact use cases, prioritize quick wins, and deploy scalable models faster.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-8.svg`
    },
    {
        title: "Cost Efficiency",
        content: "Hiring full-time AI talent or building infrastructure from scratch can strain budgets. Consultants provide flexible, on-demand expertise, reducing overhead.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-9.svg`
    },
    {
        title: "Risk Mitigation & Ethical Compliance",
        content: "AI projects carry risks like bias or data privacy breaches. Consultants implement governance frameworks, ensuring ethical AI practices and adherence to standards.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-10.svg`
    },
    {
        title: "Scalability and Future-Proofing",
        content: "AI consultants don’t just solve today’s problems - they build adaptable systems that grow with your business for seamless scalability of your AI infrastructure.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-11.svg`
    },
    {
        title: "Competitive Advantage",
        content: "Consultants help you harness predictive analytics, intelligent automation, and real-time decision-making to dominate your market faster than competitors.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-12.svg`
    },
    {
        title: "Focus on Core Business Goals",
        content: "Outsourcing team handles the technical heavy lifting, from data engineering to model deployment, freeing your staff to drive growth in their expertise areas.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-13.svg`
    },
    {
        title: "Measurable Results",
        content: "Consultants define clear KPIs, rigorously tracking progress to ensure accountability and demonstrate the tangible impact of AI investments.",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-14.svg`
    }
]

const ourExpertise = [
    {
        title: "Machine Learning",
        content: "We build sophisticated machine learning models to predict future trends, automate decision-making, and optimize processes.",
        techList: ["Deep Learning", "Reinforcement Learning", "Time Series Analysis"],
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-expertise-1.svg`
    },
    {
        title: "Natural Language Processing",
        content: "We develop intelligent NLP solutions that enable seamless communication and understanding between humans and machines.",
        techList: ["Sentiment Analysis","Chatbot Development","Text summarization"],
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-expertise-2.svg`
    },
    {
        title: "Computer Vision",
        content: "We leverage computer vision to analyze images and videos, enabling object detection, image recognition, and more.",
        techList: ["Image Recognition", "Video Analytics", "Quality Control Systems."],
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-expertise-3.svg`
    },
    {
        title: "Generative AI",
        content: "We take advantage of genAI’s power to create innovative solutions.",
        techList: ["Custom LLMs", "Content Generation", "Personalized User Experiences.", "Industry-Tailored Solutions"],
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-expertise-4.svg`
    },
]

const whyNeurond = [
    {
        title: "We Speak Your Industry’s Language - Fluently",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-neurond-code.svg`,
        description: "Generic AI consultants apply the same tools to every sector. We don’t.",
        descriptionHighLight: ["Deep Vertical Expertise:", "Pre-Built, Customizable Solutions:", "Proven Use Cases:"],
        descriptionDetail: ["Our team understands your compliance needs, workflows, and KPIs.", "Leverage our battle-tested AI frameworks for your industry, reducing implementation time by 50%.", "Real-world results tailored to your sector, driving measurable ROI from day one."]
    },
    {
        title: "The Neurond Difference: Our 5D Framework",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-neurond-framework.svg`,
        description: "Our proprietary methodology ensures success where others fail:",
        descriptionHighLight: ["Discover:", "Design:", "Develop:", "Deploy:", "Drive:"],
        descriptionDetail: ["Uncover high-impact AI opportunities in your data.", "Co-create solutions with your team—no black-box surprises.", "Build ethical, explainable models using your unique datasets.", "Integrate AI seamlessly into your workflows with zero downtime.", "Continuously refine models to adapt to market shifts."]
    },
    {
        title: "Ethics & Transparency Are Non-Negotiable",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-neurond-ethic.svg`,
        description: "Many AI firms cut corners on compliance. We won’t.",
        descriptionHighLight: ["Bias Audits:", "Explainable AI (XAI):", "GDPR/HIPAA/CCPA Ready:"],
        descriptionDetail: ["Rigorously test models for fairness across demographics.", "Get clear insights into how decisions are made.", "Build trust with data privacy and regulatory alignment."]
    },
    {
        title: "A Partner, Not a Vendor",
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-neurond-partner.svg`,
        description: "Competitors disappear post-launch. We’re with you for the long haul.",
        descriptionHighLight: ["Dedicated AI Success Managers:", "Upskilling Your Team:", "Adaptive Roadmaps:"],
        descriptionDetail: ["Get ongoing support, training, and strategy reviews.", "Free workshops to turn your staff into AI power users.", "Evolve your AI strategy as your business grows."]
    }
]

const tailoredSolutions = [
    {
        title: "Financial Services & Fintech",
        solutions: ["Spot and prevent fraudulent transactions in real-time using advanced anomaly detection algorithms.", "Boost credit risk assessment with AI-powered models that consider a wider range of data points.", "Execute trades more efficiently and profitably with AI-driven trading strategies.", "Find and mitigate financial risks with AI-powered risk assessment models.", "Increase customer experience and reduce costs with AI-powered chatbots and virtual assistants."]
    },
    {
        title: "Education",
        solutions: ["Personalize educational content and pacing to each student needs and learning styles.", "Save educators’ time by automating routine grading tasks and giving personalized feedback to students.", "Giving students personalized support and guidance through AI-powered tutoring platforms.", "Identify at-risk students and provide early interventions to improve student outcomes.", "Streamline administrative enrollment and scheduling tass to enhance efficiency."]
    },
    {
        title: "Aquaculture",
        solutions: ["Detect fish stress and monitor fish welfare to increase profitability and sustainability.", "Diagnose fish diseases early to prevent outbreaks and minimize losses.", "Track environmental conditions to ensure optimal conditions for fish growth.", "Automate the collection of data on fish growth, water quality, and other key metrics.", "Optimize feeding schedules and amounts to reduce waste and improve fish growth rates."]
    },
    {
        title: "Manufacturing",
        solutions: ["Minimize downtime and optimize equipment lifespan by predicting and preventing equipment failures.","Improve product quality and reduce waste using AI-powered visual inspection systems.","Enhance supply chain visibility and efficiency via AI-driven forecasting and logistics optimization.","Define bottlenecks and improve manufacturing processes with data analysis and machine learning.","Integrate AI with robotic systems for increased automation and efficiency in manufacturing operations."]
    },
    {
        title: "Human Resources",
        solutions: ["Optimize the recruitment process with AI-powered candidate screening and matching tools.","Analyze employee data to identify factors that contribute to employee engagement and retention.","Provide personalized feedback and coaching to employees based on their performance data.","Recommend personalized development opportunities to employees based on their skills and career goals.","Automate routine HR tasks, such as onboarding and payroll processing."]
    },
    {
        title: "Healthcare",
        solutions: ["Improve early disease detection and personalized treatment plans using machine learning on patient data.","Identify high-risk individuals for proactive interventions and improving patient outcomes.","Automate administrative tasks, freeing up healthcare professionals to focus on patient care.","Speed the drug discovery process with AI molecular modeling and clinical trial optimization.","Personalized treatments based on individual patient characteristics and genetic information."]
    },
    {
        title: "Retail & E-Commerce",
        solutions: ["Optimize inventory management and reduce waste with demand forecasting.","Enhance customer experience and drive sales with personalized product recommendations.","Maximize revenue by automatically adjusting prices based on real-time market conditions.","Identify at-risk customers and implement retention strategies to improve customer lifetime value.","Enable customers to easily find products using images or natural language queries."]
    },
    {
        title: "Marketing",
        solutions: ["Deliver personalized marketing messages and offers to customers based on their preferences and behavior.","Segment customers into different groups based on their demographics, interests, and purchase history.","Optimize marketing campaigns in real-time to maximize ROI.","Analyze social media data to understand customer sentiment and identify trends.","Enable personalized customer support and answer customer questions through AI-powered chatbots."]
    }
]

var sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
            },
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
            },
        }
    ]
};

const testimonials = [
    {
        content: "“Our partnership with Neurond has been instrumental in driving data-driven success. Their expertise in data science and analytics has consistently exceeded our expectations. Building on this strong foundation, we confidently partner with Neurond in our AI initiative. The flexibility of their AI consulting service and the ability to translate complex concepts into business value is truly impressive. We look forward to many more years of innovation and growth together.”",
        author: "John Hanrahan",
        authorPosition: "Netwealth’s CIO",
        logo: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/netwealth-logo-colored.png`
    },
    {
        content: "“Neurond’s AI team has constantly impressed us. Our team at Neurond, within a few months, became a vital and integrated part of Fishwell, sharing the same vision and drive forward, and representing a key force of Fishwell.”",
        author: " Thomas Haferlach",
        authorPosition: "Fishwell’s CTO",
        logo: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/fishwell-logo-colored.png`
    },
    {
        content: "“Neurond’s most impressive characteristic is their passion for helping us solve our problems. They truly believe in what we’re trying to achieve. Their members see the value of our work and understand our vision.”",
        author: "Chris Beak",
        authorPosition: "InCloud’s CEO",
        logo: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/incloud-logo-colored.png`
    },
    {
        content: "“We faced a complex challenge that required an innovative solution, and we knew AI technology was the key. We partnered with Neurond, who eagerly took on the task. The result? A powerful solution that significantly cuts down the time for both us and our customers. We highly recommend Neurond!”",
        author: "Jan Christian Herlitz",
        authorPosition: "Excosoft’s CEO",
        logo: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/excosoft-logo-colored.png`
    },
]

const blogArticles = [
    {
        title: "10 Leading Machine Learning Consulting Companies",
        link: "/blog/machine-learning-consulting-companies",
        date: "15/11/2024",
        thumbnail: "https://neurondwp-b776a9d209297390c027-endpoint.azureedge.net/wp-content/uploads/2024/10/machine-learning-consulting-companies.webp"
    },
    {
        title: "7 Must-Know AI Trends for Businesses to Thrive in 2025",
        link: "/blog/ai-trends",
        date: "19/11/2024",
        thumbnail: "https://neurondwp-b776a9d209297390c027-endpoint.azureedge.net/wp-content/uploads/2024/10/ai-trends.webp"
    },
    {
        title: "Top 5 Use Cases of AI in Project Management",
        link: "/blog/ai-in-project-management",
        date: "22/11/2024",
        thumbnail: "https://neurondwp-b776a9d209297390c027-endpoint.azureedge.net/wp-content/uploads/2024/11/ai-in-project-management.webp"
    },
]


const ArtificialIntelligenceConsultingPage = () => {
    useEffect(() => {
        setNavIntoView();
    }, [])

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const { t } = useTranslation()
    return (
    <ServiceTemplate>
        <SEO title={t("AI Consulting Services")} description={"Neurond offers customized AI consulting services to help your business successfully implement AI solutions into operations, paving the way to industry leadership."}/>
        <section className="service-banner content-background gradient-radial-white-blue-bg" style={{backgroundImage: `url(${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/banner-bg.jpg)`}}>
            <div className="wrapper justify-content-center align-items-start px-3">
                <div className="col-md-7 col-12 px-0">
                    <h1 className="xl-text mb-4">{bannerContent.title}</h1>
                    <p className="mb-4">{bannerContent.content}</p>
                    <a className="btn btn-cta" href="/contact/">{bannerContent.ctaText}</a>
                </div>
            </div>

            <picture>
                <source media="(min-width: 1600px)" srcset={bannerContent.backgroundImage}/>
                <img class="img-intro" src={bannerContent.backgroundImageSmall} alt={bannerContent.title}/>
            </picture>
        </section>

        <section className="trusted-client dark-blue-bg">
            <div className="wrapper --flex-row align-items-center">
                <div className="col-md-2 col-12 pl-md-0 mb-4 mb-md-0">
                    <p className="text-white h4-text text-center text-md-left">Trusted by <span className="d-inline-block">Our Partners</span></p>
                </div>
                <div className="col-md-10 col-12 row align-items-center pl-md-5 d-none d-md-flex">
                    {clientLogos.map((item, index) => {
                        return (
                            <div className="col-lg col-md-4 col" key={index}>
                                <img height="54" src={item} alt={"Logo" + index} />
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="client-logo-mobile d-md-none">
                <div className="logo-wrapper">
                    <div className="custom-icon icon-next"><FontAwesomeIcon size="xs" icon={faAngleLeft} color="#fff" /></div>
                    {clientLogos.map((item, index) => {
                        return (
                            <img className="mx-2" height="54" src={item} alt={"Logo" + index} key={index}/>
                        )
                    })}
                    <div className="custom-icon icon-prev"><FontAwesomeIcon size="xs" icon={faAngleRight} color="#fff" /></div>
                </div>
            </div>
        </section>

        <section class="section-menu" id="content-map">
            <div class="main-grid row justify-content-center section-menu__wrapper mx-auto">
                <div className="custom-icon --white-bg icon-next d-inline-block d-md-none"><FontAwesomeIcon size="xs" icon={faAngleLeft} color="#000" /></div>
                <p class="toc-entry text-center mb-0"><a class="section-menu__item px-md-5 px-sm-4 px-3 py-4 d-block active" href="#section-1">Services</a></p>
                <p class="toc-entry text-center mb-0"><a class="section-menu__item px-md-5 px-sm-4 px-3 py-4 d-block" href="#section-2">Benefits</a></p>
                <p class="toc-entry text-center mb-0"><a class="section-menu__item px-md-5 px-sm-4 px-3 py-4 d-block" href="#section-3">Approach</a></p>
                <p class="toc-entry text-center mb-0"><a class="section-menu__item px-md-5 px-sm-4 px-3 py-4 d-block" href="#section-4">Expertise</a></p>
                <p class="toc-entry text-center mb-0"><a class="section-menu__item px-md-5 px-sm-4 px-3 py-4 d-block" href="#section-5">Why Neurond?</a></p>
                <p class="toc-entry text-center mb-0"><a class="section-menu__item px-md-5 px-sm-4 px-3 py-4 d-block" href="#section-6">Industry</a></p>
                <div className="custom-icon --white-bg icon-prev d-inline-block d-md-none"><FontAwesomeIcon size="xs" icon={faAngleRight} color="#000" /></div>
            </div>
        </section>

        <section id="service-content">
            <section id="section-1" className="wrapper py-5">
                <h2 className="h2-text text-md-center mb-4">Our Artificial Intelligence Consulting Services</h2>
                <p className="sub-title-text mb-4 text-md-center">AI consulting service bridges the gap between your business goals and advanced technology.</p>

                <div className="row m-minus-15">
                    {
                        ourServices.map((item, index) => {
                            return (
                                <div className="col-lg-4 col-md-6 col-12 mt-4" key={index}>
                                    <div className="grey-box">
                                        <p>
                                            <img className="img-fluid" src={item.icon} alt={item.title}/>
                                        </p>
                                        <h3 className="h4-text --font-weight-bold my-4">{item.title}</h3>
                                        <p>{item.content}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </section>

            <section id="section-2" className="dark-blue-bg mt-4 padding-y-70" style={{position:'relative'}}>
                <div className="wrapper" style={{position:'relative',zIndex:1}}>
                    <div className="row m-minus-15">
                        <div className="col-lg-8 col-12">
                            <h2 className="h2-text text-white mb-4">Expert AI Consulting for Your Unique Needs</h2>
                            <p className="sub-title-text mb-4 text-white">The journey of adopting artificial intelligence is fraught with complexity. Expert AI consultants help you navigate this transformation smoothly and with maximum impact.</p>

                            <div className="row m-minus-15">
                                <div className="col-sm-6 col-12">
                                {ourExperts.map((item,index) => {
                                    return index < 4 && (
                                        <div className="white-box p-4 mt-4" key={index} style={{height:'auto',minHeight:'156px'}}>
                                            <img className="img-fluid float-none" src={item.icon} alt={item.title} />
                                            <h3 className="h4-text --font-weight-bold mt-4 secondary-blue">{item.title}</h3>
                                            <div className="hover-box secondary-blue">
                                                {item.content}
                                            </div>
                                        </div>
                                    )
                                })}
                                </div>
                                <div className="col-sm-6 col-12">
                                {ourExperts.map((item,index) => {
                                    return index >= 4 && (
                                        <div className="white-box p-4 mt-4" key={index} style={{height:'auto',minHeight:'156px'}}>
                                            <img className="img-fluid float-none" src={item.icon} alt={item.title} />
                                            <h3 className="h4-text --font-weight-bold mt-4 secondary-blue">{item.title}</h3>
                                            <div className="hover-box secondary-blue">
                                                {item.content}
                                            </div>
                                        </div>
                                    )
                                })}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12">

                        </div>
                    </div>
                </div>
                <img className="expert-img d-none d-lg-inline-block" src={`${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/our-expert-img.png`} alt="Our Experts"/>
            </section>

            <section id="section-3" className="padding-y-70" style={{overflow:'hidden'}}>
                <div className="wrapper --flex-row">
                    <h2 className="col-12 px-0 h2-text text-md-center mb-4">Our Consulting Approach</h2>
                    <p className="col-12 px-0 sub-title-text text-md-center mb-5">We don’t just deliver AI solutions - we partner with you to drive seamless adoption and measurable impact.</p>

                    <div id="our-process" class="col-12 px-0 our-process pt-5 d-none d-lg-block">
                        <Tab.Container defaultActiveKey={0} id="our-process-tab">
                            <Nav variant="tabs" className="our-process__nav">
                            {
                                ourProcess.map((item, index) => {
                                    return (
                                            <Nav.Item className={"our-process__nav-item-" + index}>
                                                <Nav.Link className={"our-process__nav-link"} eventKey={index}>
                                                    <img className="our-process__nav-link-icon" src={item.icon} alt={item.title + "icon"} />
                                                    <img className="our-process__nav-link-icon --hover" src={item.iconHover} alt={item.title + "icon hover"} />
                                                    <p className="our-process__nav-link-title">{item.title}</p>
                                                </Nav.Link>
                                            </Nav.Item>
                                    )
                                })
                            }
                            <img className="our-process__timeline__img mx-auto mb-5" src={`${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/process-bg.svg`} alt="Our process"/>
                            </Nav>
                            <Tab.Content className="col-md-10 col-12 px-0 px-md-5 mx-auto mt-5">
                            {
                                ourProcess.map((item, index) => {
                                    return (
                                            <Tab.Pane eventKey={index} className="our-process__tab-pane">
                                                <div className="row align-items-center m-minus-15">
                                                    <div className="col-md-3 col-12 our-process__title mb-3 mb-md-0">
                                                        <img className="img-fluid" src={item.icon} alt={item.title + "icon"}/>
                                                        <p className="mt-2 --font-weight-bold">{item.title}</p>
                                                    </div>
                                                    <div className="col-md-9 col-12 our-process__content pt-3 pt-md-0">
                                                        <p className="mb-3">{item.content}</p>
                                                        <ul className="pl-3 mb-0">
                                                            {
                                                                item.contentHighLight.map((cont, index) => {
                                                                    return (
                                                                        <li><strong>{cont}</strong> {item.contentDetail[index]}</li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                    )
                                })
                            }
                            </Tab.Content>
                        </Tab.Container>
                    </div>

                    <div className="row m-minus-15 d-none d-md-flex d-lg-none">
                    {
                        ourProcess.map((item, index) => {
                            return (
                            <div className="col-4 mb-4" key={index}>
                                <div className="grey-box py-4 px-3" style={{borderRadius: '5px'}}>
                                    <img className="img-fluid" src={item.icon} alt={item.title + "icon"}/>
                                    <p className="--font-weight-bold my-2">{item.title}</p>
                                    <p className="mb-3">{item.content}</p>
                                    <ul className="pl-3 mb-0">
                                        {
                                            item.contentHighLight.map((cont, index) => {
                                                return (
                                                    <li><strong>{cont}</strong> {item.contentDetail[index]}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                            )
                        })
                    }
                    </div>

                    <div className="d-md-none">
                    {
                        ourProcess.map((item, index) => {
                            return (
                                <Accordion className="accordion-wrapper" expanded={expanded === 'panel' + index} onChange={handleChange('panel' + index)}>
                                    <AccordionSummary
                                        aria-controls={`panel1a-content-consult-${index}`}
                                        id={`panel-header-consult-${index}`}
                                        >
                                        <Typography className="icon">
                                            <img className="img-fluid" src={item.icon} alt={item.title + "icon"}/>
                                        </Typography>
                                        <Typography className="title" style={{fontFamily:'Inter'}}>
                                            {item.title}
                                            <FontAwesomeIcon size="xs" icon={faAngleDown} color="black" />
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className="align-start">
                                        <p className="mb-3">{item.content}</p>
                                        <ul className="pl-3 mb-0">
                                            {
                                                item.contentHighLight.map((cont, index) => {
                                                    return (
                                                        <li><strong>{cont}</strong> {item.contentDetail[index]}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })
                    }
                    </div>
                </div>
            </section>

            <section id="section-4" className="padding-y-70 grey-bg">
                <div className="wrapper">
                    <h2 className="h2-text text-md-center mb-4">Our AI Expertise: <span className="d-md-block">Innovation Rooted in Experience</span></h2>
                    <p className="sub-title-text mb-4 text-md-center">Our team of experienced AI specialists possesses deep expertise in various areas:</p>

                    <div className="row m-minus-15">
                        {ourExpertise.map((item, index) => {
                            return (
                                <div className="col-lg-3 col-sm-6 col-12 mt-4" key={index}>
                                    <img width={30} height={30} src={item.icon} alt={item.title} />
                                    <h3 className="h4-text --font-weight-bold my-4">{item.title}</h3>
                                    <p className="mb-3">{item.content}</p>
                                    <ul className="pl-3">
                                        {item.techList.map((item) => {
                                            return(
                                                <li>{item}</li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            <section id="section-5" className="padding-y-70">
                <div className="wrapper">
                    <div className="row m-minus-15 align-items-center">
                        <div className="col-md-6 col-12">
                            <h2 className="h2-text mb-4">Why Neurond?</h2>
                            <p className="sub-title-text mb-5">At Neurond, we don’t just talk about AI - We engineer solutions that drive measurable growth, cut costs, and future-proof your business.</p>
                            {
                                whyNeurond.map((item, index) => {
                                    return (
                                        <Accordion className="accordion-wrapper" expanded={expanded === 'panel' + index} onChange={handleChange('panel' + index)}>
                                            <AccordionSummary
                                                expandIcon={
                                                    <>
                                                    <FontAwesomeIcon className="expand-icon" size="sm" icon={faMinus} color="black" />
                                                    <FontAwesomeIcon className="collapse-icon" size="sm" icon={faPlus} color="black" />
                                                    </>
                                                }
                                                aria-controls={`panel1a-content-${index}`}
                                                id={`panel-header-${index}`}
                                                >
                                                <Typography className="title accordion-icon" style={{backgroundImage: `url(${item.icon})`, fontSize:'20px', fontFamily:'Inter'}}>
                                                    {item.title}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography className="align-start">
                                                    <p className="mb-3">{item.description}</p>
                                                    <ul className="pl-3">
                                                    {
                                                        item.descriptionHighLight.map((des, index) => {
                                                            return(
                                                            <li>
                                                                <strong>{des}</strong> {item.descriptionDetail[index]}
                                                            </li>
                                                            )
                                                        })
                                                    }
                                                    </ul>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                })
                            }
                        </div>
                        <div className="col-md-6 col-12 text-center">
                            <img className="img-fluid" src={`${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/why-neurond.jpg`} alt="Why Neurond"/>
                        </div>
                    </div>
                </div>
            </section>

            <section id="section-6" className="dark-blue-bg padding-y-70">
                <div className="wrapper">
                    <div className="row">
                        <h2 className="h2-text text-white col-12 px-0 mb-5">Industry-Tailored Solutions</h2>

                        <div id="tailored-solutions" class="row m-minus-15 tab-columns text-white col-12 px-0 d-none d-md-flex">
                            <Tabs
                                defaultActiveKey={tailoredSolutions[0].title}
                                id="tab"
                            >
                                {
                                    tailoredSolutions.map((item, index) => {
                                        return (
                                            <Tab eventKey={item.title} title={item.title} key={index}>
                                                <div className="text-white content-text">
                                                    <ul>
                                                    {
                                                        item.solutions.map((solution, index) => {
                                                            return(
                                                                <li className="mb-2" key={index}>{solution}</li>
                                                            )
                                                        })
                                                    }
                                                    </ul>
                                                </div>
                                            </Tab>
                                        )
                                    })
                                }
                            </Tabs>
                        </div>

                        <div className="d-md-none">
                        {
                            tailoredSolutions.map((item, index) => {
                                return (
                                    <Accordion className="accordion-wrapper" expanded={expanded === 'panel' + index} onChange={handleChange('panel' + index)}>
                                        <AccordionSummary
                                            expandIcon={
                                                <>
                                                <FontAwesomeIcon className="expand-icon" size="xs" icon={faMinus} color="white" />
                                                <FontAwesomeIcon className="collapse-icon" size="xs" icon={faPlus} color="white" />
                                                </>
                                            }
                                            aria-controls={`panel1a-content-solution-${index}`}
                                            id={`panel-header-solution-${index}`}
                                            >
                                            <Typography className="title text-white" style={{fontSize:'16px', fontFamily:'Inter'}}>
                                                {item.title}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography className="align-start">
                                            <ul className="pl-3 text-white">
                                            {
                                                item.solutions.map((solution, index) => {
                                                    return(
                                                        <li className="mb-2" key={index}>{solution}</li>
                                                    )
                                                })
                                            }
                                            </ul>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })
                        }
                        </div>
                    </div>
                </div>
            </section>
        </section>

        <section className="service-testimonial padding-y-70">
            <div className="wrapper --flex-row">
                <div className="col-10 px-0">
                    <h2 className="sub-h4-text --uppercase --font-weight-bold" style={{letterSpacing: "3px",color:'#808080'}}>Testimonials</h2>
                    <p className="h2-text secondary-blue">What Our Clients Say</p>
                </div>
                <p className="col-2 px-0">
                    <img className="img-fluid" src={`${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/icon-quote-mark.svg`} alt="Testimonials" />
                </p>
                <div className="custom-slick-slider mt-5">
                    <Slider {...sliderSettings}>
                        {
                            testimonials.map((item, index) => {
                                return (
                                    <div className="service-testimonial-box" key={index}>
                                        <p className="mb-4">{item.content}</p>
                                        <div className="row align-items-end">
                                            <div className="col-7 px-0">
                                                <p className="service-testimonial-author primary-blue">{item.author}</p>
                                                <p className="service-testimonial-meta">{item.authorPosition}</p>
                                            </div>
                                            <div className="col-5 px-0 text-right">
                                                <img className="d-inline-block img-fluid" height={30} src={item.logo} alt={item.author + "Logo"} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>
            </div>
        </section>

        <section className="grey-bg py-4" style={{backgroundColor:'#fafafa'}}>
            <div className="wrapper --flex-row">
                <h2 className="col-lg-3 col-12 text-center sub-h4-text secondary-blue --uppercase --font-weight-bold">Featured On</h2>
                <div className="col-lg-3 col-sm-4 col-12 text-center mt-4 mt-lg-0">
                    <img className="img-fluid" src={`${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/logo-forbes.png`} alt="Forbes Logo"/>
                </div>
                <div className="col-lg-3 col-sm-4 col-12 text-center mt-4 mt-lg-0">
                    <img className="img-fluid" src={`${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/logo-ieee.png`} alt="IEEE Logo"/>
                </div>
                <div className="col-lg-3 col-sm-4 col-12 text-center mt-4 mt-lg-0">
                    <img className="img-fluid" src={`${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting-services/logo-clutch.png`} alt="Clutch Logo"/>
                </div>
            </div>
        </section>

        <section className="grey-bg padding-y-70">
            <div className="wrapper">
                <div className="row m-minus-15">
                    <h2 className="col-12 h3-text row align-items-center justify-content-between">
                        <span>Related articles</span>
                        <a className="float-right sub-content gray" href="/blog/"><span className="pr-2">View More</span> <FontAwesomeIcon size="1x" icon={faArrowRightLong} color="#878a93" /></a>
                    </h2>

                    {
                        blogArticles.map((item, index) => {
                            return (
                                <div className="col-md-4 col-sm-6 col-12 mt-4" key={index}>
                                    <article className="custom-article-card">
                                        <img className="custom-article-card__thumbnail img-fluid" src={item.thumbnail} alt={item.title}/>
                                        <div className="custom-article-card__body">
                                            <a className="d-block sub-content black --font-weight-bold mt-2 mb-4" href={item.link}>{item.title}</a>
                                            <p className="sub-sub-content gray row justify-content-between">{item.date} <FontAwesomeIcon size="1x" icon={faArrowRightLong} color="#0060ff" /></p>
                                        </div>
                                    </article>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>


    </ServiceTemplate>
    )
}

export default ArtificialIntelligenceConsultingPage


function setNavIntoView() {
    var offset = 20;
    document.addEventListener("scroll", function() {
        var ele = document.getElementsByClassName("section-menu__item active")[0];
        document.getElementById("content-map").scrollTo(ele.offsetLeft - offset, 0);
    });
}
