import React, { useEffect, createRef } from "react"
import Layout from "../components/layout"
import PropTypes from "prop-types"

const ServiceTemplate = ({ children }) => {

    useEffect(() => {
        setClassForToCItemOnScroll();
        setClassForToCItemOnClick();
        setSideMenuPositionOnScroll();
    }, [])

    const ref = createRef()

    return (
      <Layout showHeaderBG={true}>
          {children}
      </Layout>
    )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
export default ServiceTemplate


function setSideMenuPositionOnScroll() {
    const triggerPos = -50;

    var articleBottom = document.querySelector("#service-content").offsetHeight;
    var toc = document.querySelector(".section-menu__item");

    window.addEventListener("scroll", function() {
      if(document.documentElement.scrollTop > triggerPos && document.documentElement.scrollTop <= articleBottom) {
            toc.classList.add("--fixed-pos");
            toc.classList.remove("--slide-top");
      } else if(document.documentElement.scrollTop > articleBottom) {

            toc.classList.add("--slide-top");
      }
      else {
            toc.classList.remove("--fixed-pos", "--slide-top");
      }
    })
}

function getTOCHeadings() {
    var headings = document.getElementsByClassName("section-menu__item");
    var tocHeadings = [];

    for(var i = 0; i < headings.length; i++) {
        tocHeadings.push(headings[i]);
    }

    return tocHeadings;
}

function getSectionID(sectionTOC) {
    return sectionTOC.getAttribute("href").replace("#","");
}

function setClassForToCItemOnScroll() {
    var tocHeadings = getTOCHeadings();

    window.addEventListener("scroll", function() {
      tocHeadings.forEach( tocHeading => {
        const offset = -150;
        var sectionID = getSectionID(tocHeading);

        var section = document.getElementById(sectionID);

        if(typeof(section) != 'undefined' && section != null){
          var sectionPos = document.getElementById(sectionID).offsetTop + offset;
          var anchorEl = document.querySelector("a[href=\"#" + getSectionID(tocHeading) + "\"");

          if(document.documentElement.scrollTop >= sectionPos) {
            tocHeadings.forEach( toc => {
              toc.classList.remove("active");
            });
            anchorEl.classList.add("active");
          }
        }
      });
    })
}

function setClassForToCItemOnClick() {
    const offset = -120;
    var anchorEls = document.querySelectorAll(".section-menu__item");

    anchorEls.forEach(anchor => {
      anchor.addEventListener("click", function(e) {
        e.preventDefault();

        var sectionID = getSectionID(anchor);
        var sectionPos = document.getElementById(sectionID).offsetTop + offset;
        window.scrollTo(0, sectionPos);
      });
    });
}
